import React from 'react';
import Header from '../header';
import Footer from '../footer';
import { useLocation } from 'react-router-dom';
import './index.css';

const domObj = {
   '“昆山崴鼎薄膜科技有限公司年生产各类塑胶薄膜涂层、复合、加工240吨项目”验收后变动环境影响分析公示': (
      <>
        <h2 className="document-h">
         “昆山崴鼎薄膜科技有限公司年生产各类塑胶薄膜涂层、复合、加工240吨项目”验收后变动环境影响分析公示
        </h2>
        <p className="document-p">
          发布单位：昆山崴鼎薄膜科技有限公司
        </p>
        <h3>1、说明</h3>
        <p className="document-p">为提高涂布废气处理效率、实现资源再利用、余热再利用等，拟对《昆山崴鼎薄膜科技有限公司年生产各类塑胶薄膜涂层、复合、加工240吨项目（昆环建［2006］3715号）》（后文简称“原项目”）涂布废气处理设施进行改造，即将原项目涂布废气集中收集后经超氧纳米微气泡处理后经1根20m高排气筒排放，改造为有机废气集中收集后经过冷凝回收+RTO装置处理后经1根15m高排气筒排放。变动后产品种类、生产能力、生产工艺等均不变。</p>
        <h3>2、建设项目概要</h3>
        <p className="document-p">（1）项目名称：昆山崴鼎薄膜科技有限公司年生产各类塑胶薄膜涂层、复合、加工240吨项目验收后变动环境影响分析</p>
        <p className="document-p">（2）项目地点：昆山市锦溪镇百胜路215号</p>
        <h3>3、建设项目验收后变动环境影响分析文件</h3>
        <p className="document-p">（1）报告表全文</p>
        <p className="document-p">（2）报告全文查阅期限：</p>
        <h3>4、建设单位名称和联系方式</h3>
        <p className="document-p">建设单位名称：昆山崴鼎薄膜科技有限公司</p>
        <p className="document-p">联系方式：15250299852</p>
        <p className="document-p">联系人：邹声平</p>
        <p className="document-p">邮箱：1783068738@qq.com</p>
        <p className="document-p">联系地址：昆山市锦溪镇百胜路215号</p>
        <h3>5、征求公众意见的主要事项</h3>
        <p className="document-p">公众对本项目建设环境保护方面的意见和建议（不接纳与环境保护无关的问题）。</p>
        <h3>6、公众意见反馈</h3>
        <p className="document-p">（1）反馈方式</p>
        <p className="document-p">即日起，公众可向建设单位通过电话，发送电子邮件、信函等方式发表关于该建设项目验收后变动环境影响分析工作的意见看法（不接受与环境无关的问题）。</p>
        <p className="document-p">（2）反馈时限</p>
        <p className="document-p">本公告发布之日起20个工作日内（信函以邮戳日期为准）</p>
        <p className="document-t">昆山崴鼎薄膜科技有限公司</p>
        <p className="document-t">2025-1-16</p>
        <h3>7、附件</h3>
          <p className="document-p">
            <div className="margin-10">
              <a href="/PDF/20250116崴鼎变动影响分析.pdf" target="_down">
                1 验收后变动环境影响分析报告.pdf
              </a>
            </div>
             <div className="margin-10">
              <a href="/PDF/20250116评审意见.pdf" target="_down">
                2 昆山崴鼎薄膜科技有限公司 评审意见.pdf
              </a>
          </div>
        </p>
    </>
    ),
    '昆山淀富精密组件有限公司金属零件生产项目（第二阶段）竣工环境保护验收公示': (
    <>
      <h2 className="document-h">
        昆山淀富精密组件有限公司金属零件生产项目（第二阶段）竣工环境保护验收公示
      </h2>
      <p className="document-p">
       根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。昆山淀富精密组件有限公司金属零件生产项目（第二阶段）已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
      </p>
      <p className="document-p">
       公众可将意见或建议通过来电、来信的方式向昆山淀富精密组件有限公司反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">公示期：2024年12月31日至2025年1月27日</p>
      <p className="document-p">联系地址：昆山市玉山镇新塘路777号7号房</p>
      <p className="document-p">联系电话：15115015992</p>
      <p className="document-p">电子邮箱：js.gmoa@hk-dianfu.com</p>
      <p className="document-p">具体情况如下：</p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>建设单位</th>
              <th>项目名称</th>
              <th>建设地点</th>
              <th>验收监测报告编制单位</th>
              <th>公示文件</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>昆山淀富精密组件有限公司</td>
              <td>昆山淀富精密组件有限公司金属零件生产项目（第二阶段）</td>
              <td>昆山市玉山镇新塘路777号7号房</td>
              <td>昆山昆越环境技术有限公司</td>
              <td>详见附件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="document-t">昆山淀富精密组件有限公司</p>
      <p className="document-t">2024-12-31</p>
      <p className="document-p">
        <div className="margin-10">附件：</div>
        <div className="margin-10">
          <a href="/PDF/13验收报告.pdf" target="_down">
            1 昆山淀富精密组件有限公司-验收监测报告.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/13一般变动变动环境影响分析.pdf" target="_down">
            2 昆山淀富精密组件有限公司 一般变动分析.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/13其他需要说明的事项.pdf" target="_down">
            3 昆山淀富精密组件有限公司 其他需要说明的事项.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/13验收意见.pdf" target="_down">
            4 昆山淀富精密组件有限公司 验收意见.pdf
          </a>
        </div>
      </p>
	</>
  ), 
  '昆山思特威集成电路有限公司图像传感器芯片测试项目建设项目配套的环境保护设施调试信息公示': (
    <>
      <h2 className="document-h">
        昆山思特威集成电路有限公司图像传感器芯片测试项目建设项目配套的环境保护设施调试信息公示
      </h2>
      <p className="document-p">
       根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“昆山思特威集成电路有限公司图像传感器芯片测试项目”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
      </p>
      <h3>一、调试日期：</h3>
      <p className="document-p">计划调试开始时间：2024年12月25日</p>
      <p className="document-p">预计调试完成时间：2024年1月25日</p>
      <h3>二、征求公众意见的范围：</h3>
      <p className="document-p">
        本建设项目周边环境影响区域内的居民、单位等公众。
      </p>
      <h3>三、公众反馈方式：</h3>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山思特威集成电路有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <h3>四、建设单位名称及联系方式：</h3>
      <p className="document-p">建设单位：昆山思特威集成电路有限公司</p>
      <p className="document-p">联系地址：昆山市锦溪镇锦顺路188号</p>
      <p className="document-p">联系电话：0512-36867965</p>
      <p className="document-p">电子邮箱：348074219@qq.com</p>
      <p className="document-t">昆山思特威集成电路有限公司</p>
      <p className="document-t">2024-12-15</p>
	</>
  ),
      '昆山思特威集成电路有限公司图像传感器芯片测试项目建设项目竣工公示': (
    <>
      <h2 className="document-h">
        昆山思特威集成电路有限公司图像传感器芯片测试项目建设项目竣工公示
      </h2>
      <p className="document-p">
        由昆山思特威集成电路有限公司建设的“昆山思特威集成电路有限公司图像传感器芯片测试项目”主体工程、配套工程及环境保护设施已于2024年12月15日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        <strong>
         公众可将意见或建议来电、来信向昆山思特威集成电路有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
        </strong>
        特此公告！
      </p>
      <p className="document-p">联系地址：昆山市锦溪镇锦顺路188号</p>
      <p className="document-p">联系电话：0512-36867965</p>
      <p className="document-p">电子邮箱：348074219@qq.com</p>
	  <p className="document-t">昆山思特威集成电路有限公司</p>
	  <p className="document-t">2024-12-15</p>
	</>
  ),
      '“世硕电子（昆山）有限公司建设项目”验收后变动环境影响分析公示': (
      <>
        <h2 className="document-h">
          “世硕电子（昆山）有限公司建设项目”验收后变动环境影响分析公示
        </h2>
        <p className="document-p">
          发布单位：世硕电子（昆山）有限公司
        </p>
        <h3>1、说明</h3>
        <p className="document-p">由于生产经营需要，为提高产品品质及加强自动化生产能力、简化生产线人力、降低企业生产成本、提高生产效率，变动内容如下：</p>
        <p className="document-p">（1）PCB板生产线改造</p>
        <p className="document-p">a.拟在F9厂房1F的SMT自动化生产线增加机械手臂等组装设备；</p>
        <p className="document-p">b.拟在F9厂房2F的SMT自动化生产线增加保压机等组装设备，减少了停机放置零件的时间；</p>
        <p className="document-p">c.拟在F12厂房1F的SMT自动化生产线增加贴片机、测试设备等组装设备。</p>
        <p className="document-p">（2）智能手机生产线改造</p>
        <p className="document-p">拟在F12厂房2F、3F，F13厂房1F、2F、3F，F14厂房1F，F15厂房3F的智能手机生产线增加解锁治具、手工工具、折弯治具、折弯机、压合治具保压设备、非连杆半自动治具、定位清洁治具、固定治具、拆卸治具、流水线输送机、上下料机、贴附保压机、穿梭机、自动放说明书设备、撕膜机、自动合盖设备、自动撕膜滚贴设备、礼盒开盒及上料机、贴膜及手机入盒设备、自动放数据线设备、自动贴连接纸到上盖设备、贴标机、上料机、下料机、检测设备等组装辅助设备。</p>
        <p className="document-p">本次变动项目与江苏昆山经济技术开发区管理委员会对投资项目备案（备案证号：昆开备〔2024〕157号）内容一致，该项目不分期建设。</p>
        <h3>2、建设项目概要</h3>
        <p className="document-p">（1）项目名称：世硕电子（昆山）有限公司建设项目验收后变动环境影响分析</p>
        <p className="document-p">（2）项目地点：昆山开发区丁香路252号、玫瑰路68号</p>
        <h3>3、建设项目验收后变动环境影响分析文件</h3>
        <p className="document-p">（1）报告表全文</p>
        <p className="document-p">（2）报告全文查阅期限：</p>
        <h3>4、建设单位名称和联系方式</h3>
        <p className="document-p">建设单位名称：世硕电子（昆山）有限公司</p>
        <p className="document-p">联系方式：0512-57676666/67955</p>
        <p className="document-p">联系人：陈放</p>
        <p className="document-p">邮箱：Andrea6_Chen@pegatroncorp.com</p>
        <p className="document-p">联系地址：昆山开发区丁香路252号、玫瑰路68号</p>
        <h3>5、征求公众意见的主要事项</h3>
        <p className="document-p">公众对本项目建设环境保护方面的意见和建议（不接纳与环境保护无关的问题）。</p>
        <h3>6、公众意见反馈</h3>
        <p className="document-p">（1）反馈方式</p>
        <p className="document-p">即日起，公众可向建设单位通过电话，发送电子邮件、信函等方式发表关于该建设项目验收后变动环境影响分析工作的意见看法（不接受与环境无关的问题）。</p>
        <p className="document-p">（2）反馈时限</p>
        <p className="document-p">本公告发布之日起20个工作日内（信函以邮戳日期为准）</p>
        <h3>7、附件</h3>
          <p className="document-p">
            <div className="margin-10">
              <a href="/PDF/14建设项目验收后变动环境影响分析.pdf" target="_down">
                1 建设项目验收后变动环境影响分析.pdf
              </a>
            </div>
        </p>
    </>
    ),
  '昆山淀富精密组件有限公司金属零件生产项目（第二阶段）建设项目配套的环境保护设施调试信息公示': (
    <>
      <h2 className="document-h">
        昆山淀富精密组件有限公司金属零件生产项目（第二阶段）建设项目配套的环境保护设施调试信息公示
      </h2>
      <p className="document-p">
       根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“昆山淀富精密组件有限公司金属零件生产项目（第二阶段）”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
      </p>
      <h3>一、调试日期：</h3>
      <p className="document-p">计划调试开始时间：2024年11月25日</p>
      <p className="document-p">预计调试完成时间：2024年12月25日</p>
      <h3>二、征求公众意见的范围：</h3>
      <p className="document-p">
        本建设项目周边环境影响区域内的居民、单位等公众。
      </p>
      <h3>三、公众反馈方式：</h3>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山淀富精密组件有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <h3>四、建设单位名称及联系方式：</h3>
      <p className="document-p">建设单位：昆山淀富精密组件有限公司</p>
      <p className="document-p">联系地址：昆山市玉山镇新塘路777号7号房</p>
      <p className="document-p">联系电话：0512-57772109</p>
      <p className="document-p">电子邮箱：js.zjl@hk-dianfu.com</p>
      <p className="document-t">昆山淀富精密组件有限公司</p>
      <p className="document-t">2024-11-15</p>
	</>
  ),
    '昆山淀富精密组件有限公司金属零件生产项目（第二阶段）建设项目竣工公示': (
    <>
      <h2 className="document-h">
        昆山淀富精密组件有限公司金属零件生产项目（第二阶段）建设项目竣工公示
      </h2>
      <p className="document-p">
        由昆山淀富精密组件有限公司建设的“昆山淀富精密组件有限公司金属零件生产项目（第二阶段）”主体工程、配套工程及环境保护设施已于2024年11月15日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        <strong>
          公众可将意见或建议通过来电、来信的方式向昆山淀富精密组件有限公司反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）
        </strong>
        特此公告！
      </p>
      <p className="document-p">联系地址：昆山市玉山镇新塘路777号7号房</p>
      <p className="document-p">联系电话：0512-57772109</p>
      <p className="document-p">电子邮箱：js.zjl@hk-dianfu.com</p>
	  <p className="document-t">昆山淀富精密组件有限公司</p>
	  <p className="document-t">2024-11-15</p>
	</>
  ),
      '“麦斯展览展示工程（昆山）有限责任公司木质展示柜加工项目”验收后变动环境影响分析公示': (
      <>
        <h2 className="document-h">
          “麦斯展览展示工程（昆山）有限责任公司木质展示柜加工项目”验收后变动环境影响分析公示
        </h2>
        <p className="document-p">
          发布单位：麦斯展览展示工程（昆山）有限责任公司
        </p>
        <h3>1、说明</h3>
        <p className="document-p">变动内容如下：</p>
        <p className="document-p">（1）由于生产经营需求，2024年9月25日将博德奕创展览展示工程（昆山）有限公司合法持有的博德奕创展览展示工程（昆山）有限公司木质展示柜加工项目（昆环建[2019]1973号）全部资产（包含但不限于生产及辅助设备、文件、资料等）转让给麦斯展览展示工程（昆山）有限责任公司所有。</p>
        <p className="document-p">根据《中华人民共和国环境影响评价法》第二十四条，建设项目的环境影响评价文件经批准后，建设项目的性质、规模、地点、采用的生产工艺或者防治污染、防止生态破坏的措施发生重大变动的，建设单位应当重新报批建设项目的环境影响评价文件。本次变动属于仅运营单位变更，建设项目的建设性质、规模、地点、采用的生产工艺等均未发生变动。</p>
        <p className="document-p">麦斯展览展示工程（昆山）有限责任公司成立于2023年1月11日，注册和建设地址为昆山市淀山湖镇黄浦江南路225号，经营范围为许可项目：住宅室内装饰装修；建设工程设计；建设工程施工（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准）一般项目：会议及展览服务；工程管理服务；市场营销策划；礼仪服务；图文设计制作；广告设计、代理；广告制作；平面设计；广告发布；专业保洁、清洗、消毒服务；建筑材料销售；电线、电缆经营；防腐材料销售；保温材料销售；建筑装饰材料销售；建筑用金属配件销售；光缆销售；日用玻璃制品销售；日用百货销售；地板销售；门窗销售；轻质建筑材料销售；专业设计服务；配电开关控制设备销售；工艺美术品及收藏品零售（象牙及其制品除外）；工艺美术品及礼仪用品销售（象牙及其制品除外）；工艺美术品及礼仪用品制造（象牙及其制品除外）；工艺美术品及收藏品批发（象牙及其制品除外）；木制玩具制造；普通货物仓储服务（不含危险化学品等需许可审批的项目）；国内货物运输代理；国际货物运输代理；家具制造；日用品销售；家具零配件生产；五金产品零售；五金产品批发；日用木制品制造；日用木制品销售；家具安装和维修服务（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）</p>
        <p className="document-p">（2）为增加开料的工作效率，拟增加1台全自动裁板锯，变动后佳诺威中纤板、玮品多层胶合板、指接板年用量不增加，木制品展示柜产能不增加。公司行业类别及代码为C2110木质家具制造，对照《建设项目环境影响评价分类管理名录》（2021年版），变动内容属于仅切割，不属于新、改、扩建项目范畴，不纳入建设项目环境影响评价管理。</p>
        <p className="document-p">（3）为适应生产需求的变化，拟将6号房钣金加工车间（切割、打磨（焊接前）、焊接）、封边平面调整至4号房，属于《污染影响类建设项目重大变动清单（试行）》中“在原厂址附近调整（调整后厂址红线范围与原厂址红线范围有重叠部分的情形）”。租赁厂房建筑面积由8089.21平方米变化为6680平方米。</p>
        <h3>2、建设项目概要</h3>
        <p className="document-p">（1）项目名称：麦斯展览展示工程（昆山）有限责任公司木质展示柜加工项目</p>
        <p className="document-p">（2）项目地点：昆山市淀山湖镇黄浦江南路225号</p>
        <h3>3、建设项目验收后变动环境影响分析文件</h3>
        <p className="document-p">（1）报告表全文</p>
        <p className="document-p">（2）报告全文查阅期限：</p>
        <h3>4、建设单位名称和联系方式</h3>
        <p className="document-p">建设单位名称：麦斯展览展示工程（昆山）有限责任公司</p>
        <p className="document-p">联系方式：13801742612</p>
        <p className="document-p">联系人：刘先生</p>
        <p className="document-p">邮箱：1783068738@qq.com</p>
        <p className="document-p">联系地址：昆山市淀山湖镇黄浦江南路225号</p>
        <h3>6、公众意见反馈</h3>
        <p className="document-p">（1）反馈方式</p>
        <p className="document-p">即日起，公众可向建设单位通过电话，发送电子邮件、信函等方式发表关于该建设项目验收后变动环境影响分析工作的意见看法（不接受与环境无关的问题）。</p>
        <p className="document-p">（2）反馈时限</p>
        <p className="document-p">本公告发布之日起20个工作日内（信函以邮戳日期为准）</p>
        <h3>7、附件</h3>
          <p className="document-p">
            <div className="margin-10">
              <a href="/PDF/13建设项目验收后变动环境影响分析.pdf" target="_down">
                1 建设项目验收后变动环境影响分析.pdf
              </a>
            </div>
            <div className="margin-10">
              <a href="/PDF/13专家咨询意见.pdf" target="_down">
                2 专家咨询意见.pdf
              </a>
            </div>
        </p>
    </>
    ),
     '汉达精密电子（昆山）有限公司中央大道分厂2023年危险废物污染环境防治信息': (
    <>
      <h2 className="document-h">
        汉达精密电子（昆山）有限公司中央大道分厂2023年危险废物污染环境防治信息
      </h2>
      <p className="document-p">
        汉达精密电子（昆山）有限公司中央大道分厂原先为汉达精密电子（昆山）有限公司B厂区，于2016年变更为中央大道分厂，位于昆山综合保税区B区中央大道168号。经营范围为：生产计算机零部件、橡胶制品、塑料制品、电子产品及其零配件、医疗器具塑料壳、玩具及其相关配件、汽车零部件及配件；精密注塑模具、模具组件、模具加工及设计；12.9级及以上高强度紧固件制造；蓄冷、蓄热、节水、节能、环保设备的技术开发、制造与综合利用；销售自产产品及相关技术配套服务并提供维修服务。现有全厂产能为K21厂房2#喷涂车间：计算机塑胶外壳喷涂100万套/年、医疗器具塑胶外壳喷涂10万套/年；K21厂房1#喷涂车间：游戏机控制手柄上盖喷涂500万个/年；K21厂房、K23厂房：200*320笔记型计算机外壳1500万套/年、120*160游戏机控制手柄500万个/年、计算机外壳支架15.6万套/年、塑胶件外壳580万套/年、计算机零部件800万套/年；K21厂房：汽车、摩托车等金属冲压24万件/年；K28厂房：ABS塑料粒子665吨/年、PP塑料粒子665吨/年，年产游戏机配件1800万件。
      </p>
      <p className="document-p">
        已按照《危险废物贮存污染控制标准》（GB18597-2023）建设一座120.972平方米的危险废物贮存设施，2023年危险废物的产生及处置情况如下表。
      </p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>序号</th>
              <th>废物类型</th>
              <th>废物代码</th>
              <th>废物名称</th>
              <th>单位</th>
              <th>产生量</th>
              <th>处置单位行政区划</th>
              <th>处置单位名称</th>
              <th>处置单位许可证编号</th>
              <th>处置单位经营方式</th>
              <th>实际转移量</th>
              <th>上年贮存量</th>
              <th>2023年底贮存量</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <th>HW06</th>
              <th>900-404-06</th>
              <th>稀释剂</th>
              <th>吨</th>
              <th>0.1</th>
              <th>苏州市,昆山市</th>
              <th>昆山市利群固废处理有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>0.1</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>2</th>
              <th>HW06</th>
              <th>900-404-06</th>
              <th>清洗废液</th>
              <th>吨</th>
              <th>0.2</th>
              <th>苏州市,昆山市</th>
              <th>昆山市利群固废处理有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>0.2</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>3</th>
              <th>HW08</th>
              <th>900-249-08</th>
              <th>废机油</th>
              <th>吨</th>
              <th>0.6</th>
              <th>徐州市,丰县</th>
              <th>江苏弘德环保科技有限公司</th>
              <th>JS0321OOI591-1</th>
              <th>D10</th>
              <th>0.6</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>4</th>
              <th>HW08</th>
              <th>900-249-08</th>
              <th>废油桶</th>
              <th>吨</th>
              <th>0.25</th>
              <th>徐州市,丰县</th>
              <th>江苏弘德环保科技有限公司</th>
              <th>JS0321OOI591-1</th>
              <th>D10</th>
              <th>0.25</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>5</th>
              <th>HW09</th>
              <th>900-006-09</th>
              <th>含油废水</th>
              <th>吨</th>
              <th>0.25</th>
              <th>徐州市,丰县</th>
              <th>江苏弘德环保科技有限公司</th>
              <th>JS0321OOI591-1</th>
              <th>D10</th>
              <th>0.3</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>6</th>
              <th>HW09</th>
              <th>900-006-09</th>
              <th>废切削液</th>
              <th>吨</th>
              <th>0.3</th>
              <th>苏州市,昆山市</th>
              <th>昆山市利群固废处理有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>0.3</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>7</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>废漆渣</th>
              <th>吨</th>
              <th>16.338</th>
              <th>徐州市,丰县</th>
              <th>江苏弘德环保科技有限公司</th>
              <th>JS0321OOI591-1</th>
              <th>D10</th>
              <th>18.24</th>
              <th>1.902</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>8</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>废漆渣</th>
              <th>吨</th>
              <th>6.373</th>
              <th>苏州市,吴中区</th>
              <th>苏州市吴中区固体废弃物处理有限公司</th>
              <th>JS0506OOI558-5</th>
              <th>D10</th>
              <th>6.373</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>9</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>废漆渣</th>
              <th>吨</th>
              <th>6.373</th>
              <th>苏州市,昆山市</th>
              <th>昆山市利群固废处理有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>38.303</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>10</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>废漆渣</th>
              <th>吨</th>
              <th>40.8325</th>
              <th>淮安市,淮阴区</th>
              <th>淮安华科环保科技有限公司</th>
              <th>JS0804OOI551-2</th>
              <th>D10</th>
              <th>40.8325</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>11</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>污泥</th>
              <th>吨</th>
              <th>2.5</th>
              <th>徐州市,丰县</th>
              <th>江苏弘德环保科技有限公司</th>
              <th>JJS0321OOI591-1</th>
              <th>D10</th>
              <th>1.95</th>
              <th>0</th>
              <th>0.55</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>12</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>污泥</th>
              <th>吨</th>
              <th>2</th>
              <th>苏州市,吴中区</th>
              <th>苏州市吴中区固体废弃物处理有限公司</th>
              <th>JS0506OOI558-5</th>
              <th>D10</th>
              <th>2</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>13</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>污泥</th>
              <th>吨</th>
              <th>3.499</th>
              <th>苏州市,昆山市</th>
              <th>昆山市利群固废处理有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>3.499</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>14</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>污泥</th>
              <th>吨</th>
              <th>3.499</th>
              <th>淮安市,淮阴区</th>
              <th>淮安华科环保科技有限公司</th>
              <th>JS0804OOI551-2</th>
              <th>D10</th>
              <th>5.046</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>15</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>喷涂废水</th>
              <th>吨</th>
              <th>0.2</th>
              <th>苏州市,昆山市</th>
              <th>昆山市利群固废处理有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>0.2</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>16</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>喷涂废水</th>
              <th>吨</th>
              <th>0.6</th>
              <th>淮安市,淮阴区</th>
              <th>淮安华科环保科技有限公司</th>
              <th>JS0804OOI551-2</th>
              <th>D10</th>
              <th>0.6</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>17</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>废网版</th>
              <th>吨</th>
              <th>0.076</th>
              <th>苏州市,昆山市</th>
              <th>昆山市利群固废处理有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>0.076</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>18</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>废素材</th>
              <th>吨</th>
              <th>0.0325</th>
              <th>苏州市,昆山市</th>
              <th>昆山市利群固废处理有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>0.0325</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>19</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>水幕板废水</th>
              <th>吨</th>
              <th>0.2</th>
              <th>徐州市,丰县</th>
              <th>江苏弘德环保科技有限公司</th>
              <th>JS0321OOI591-1</th>
              <th>D10</th>
              <th>0.2</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>20</th>
              <th>HW12</th>
              <th>900-252-12</th>
              <th>废架具</th>
              <th>吨</th>
              <th>0.2</th>
              <th>徐州市,丰县</th>
              <th>江苏弘德环保科技有限公司</th>
              <th>JS0321OOI591-1</th>
              <th>D10</th>
              <th>0.142</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>21</th>
              <th>HW49</th>
              <th>900-039-49</th>
              <th>废活性炭</th>
              <th>吨</th>
              <th>14.78</th>
              <th>苏州市,吴江区</th>
              <th>苏州巨联环保有限公司</th>
              <th>JSSZ0584OOD086-5</th>
              <th>R5</th>
              <th>14.78</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>22</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废抹布</th>
              <th>吨</th>
              <th>0.381</th>
              <th>徐州市,丰县</th>
              <th>江苏弘德环保科技有限公司</th>
              <th>JS0321OOI591-1</th>
              <th>D10</th>
              <th>0.381</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>23</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废抹布</th>
              <th>吨</th>
              <th>0.327</th>
              <th>苏州市,吴中区</th>
              <th>苏州市吴中区固体废弃物处理有限公司</th>
              <th>JS0506OOI558-5</th>
              <th>D10</th>
              <th>0.327</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>24</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废抹布</th>
              <th>吨</th>
              <th>1.298</th>
              <th>苏州市,昆山市</th>
              <th>昆山市利群固废处理有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>1.298</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>25</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废抹布</th>
              <th>吨</th>
              <th>1.06</th>
              <th>淮安市,淮阴区</th>
              <th>淮安华科环保科技有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>1.06</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>26</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废包装桶</th>
              <th>吨</th>
              <th>2.478</th>
              <th>徐州市,丰县</th>
              <th>江苏弘德环保科技有限公司</th>
              <th>JS0321OOI591-1</th>
              <th>D10</th>
              <th>3.35</th>
              <th>0.872</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>27</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废包装桶</th>
              <th>吨</th>
              <th>1.737</th>
              <th>苏州市,吴中区</th>
              <th>苏州市吴中区固体废弃物处理有限公司</th>
              <th>JS0506OOI558-5</th>
              <th>D10</th>
              <th>1.737</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>28</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废包装桶</th>
              <th>吨</th>
              <th>8.617</th>
              <th>苏州市,昆山市</th>
              <th>昆山市利群固废处理有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>5.617</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>29</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废包装桶</th>
              <th>吨</th>
              <th>8.4315</th>
              <th>淮安市,淮阴区</th>
              <th>淮安华科环保科技有限公司</th>
              <th>JS0804OOI551-2</th>
              <th>D10</th>
              <th>8.4315</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>30</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废过滤材料</th>
              <th>吨</th>
              <th>0.76</th>
              <th>徐州市,丰县</th>
              <th>江苏弘德环保科技有限公司</th>
              <th>JS0321OOI591-1</th>
              <th>D10</th>
              <th>1.338</th>
              <th>0.578</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>31</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废过滤材料</th>
              <th>吨</th>
              <th>0.763</th>
              <th>苏州市,吴中区</th>
              <th>苏州市吴中区固体废弃物处理有限公司</th>
              <th>JS0506OOI558-5</th>
              <th>D10</th>
              <th>0.763</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>32</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废过滤材料</th>
              <th>吨</th>
              <th>2.5425</th>
              <th>苏州市,昆山市</th>
              <th>昆山市利群固废处理有限公司</th>
              <th>JS0583OOI578-1</th>
              <th>D10</th>
              <th>2.5425</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>33</th>
              <th>HW49</th>
              <th>900-041-49</th>
              <th>废过滤材料</th>
              <th>吨</th>
              <th>2.48</th>
              <th>淮安市,淮阴区</th>
              <th>淮安华科环保科技有限公司</th>
              <th>JS0804OOI551-2</th>
              <th>D10</th>
              <th>2.48</th>
              <th>0</th>
              <th>0</th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  ),
    '昆山佳合朔精密电子有限公司2023年危险废物污染环境防治信息': (
    <>
      <h2 className="document-h">
        昆山佳合朔精密电子有限公司2023年危险废物污染环境防治信息
      </h2>
      <p className="document-p">
        昆山佳合朔精密电子有限公司成立于2013年11月12日，注册资金2000万元整，现位于昆山市锦溪镇昆开路88号3号房。经营范围：电子产品零部件、汽车零部件、电动工具、塑胶零件的销售；五金加工；金属制品、金属模具、自动化设备的生产、销售；货物及技术的进出口业务。（依法须经批准的项目,经相关部门批准后方可开展经营活动）一般项目：金属制品研发;汽车零部件研发;摩托车零部件研发；电机及其控制系统研发；家用电器研发；机械设备研发；五金产品研发；技术推广服务；5G通信技术服务（除依法须经批准的项目外,凭营业执照依法自主开展经营活动）。设计年生产铝合金压铸件1250万件。
      </p>
      <p className="document-p">
        已按照《危险废物贮存污染控制标准》（GB18597-2023）标准建设一座40平方米的危险废物贮存设施，危险废物主要为废脱模剂、污泥，2023年危险废物的产生及处置情况如下表。
      </p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>序号</th>
              <th>废物类型</th>
              <th>废物代码</th>
              <th>废物名称</th>
              <th>单位</th>
              <th>产生量</th>
              <th>行政区划</th>
              <th>单位名称</th>
              <th>许可证编号</th>
              <th>经营方式</th>
              <th>实际转移量</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <th>HW08</th>
              <th>900-210-08</th>
              <th>污泥</th>
              <th>吨</th>
              <th>4.155</th>
              <th>常州市,武进区</th>
              <th>江苏钦越环保科技有限公司</th>
              <th>JSCZ0412OOD077-2</th>
              <th>D9</th>
              <th>4.155</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>2</th>
              <th>HW09</th>
              <th>900-007-09</th>
              <th>废脱模剂</th>
              <th>吨</th>
              <th>9.956</th>
              <th>常州市,武进区</th>
              <th>江苏钦越环保科技有限公司</th>
              <th>JSCZ0412OOD077-2</th>
              <th>D9</th>
              <th>9.956</th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  ),
    '苏州怡合达自动化科技有限公司2023年危险废物污染环境防治信息': (
    <>
      <h2 className="document-h">
        苏州怡合达自动化科技有限公司2023年危险废物污染环境防治信息
      </h2>
      <p className="document-p">
        苏州怡合达自动化科技有限公司成立于2018年01月25日，位于昆山市千灯镇许塘路9号。主要从事自动化设备及配件的设计、研发、生产、加工、销售、安装、维修及改造。公司以“推动智能制造赋能中国制造”为企业愿景，致力打造行业领先的FA工厂自动化零部件一站式供应商。公司以平台化为支撑，以信息和数字化为驱动，充分整合社会资源，链接自动化设备行业上下游资源，以标准设定和产品开发为起点，遵循“产品供给-平台整合-生态驱动”的发展路径，逐渐提高自动化设备中零部件标准化、模块化、组件化的覆盖比例，提升自动化设备供给效率，降低综合成本，最终推动自动化行业的技术进步。
      </p>
      <p className="document-p">
        已按照《危险废物贮存污染控制标准》（GB18597-2023）标准建设一座20平方米的危险废物贮存设施，危险废物主要为废切削液、废液压油，2023年危险废物的产生及处置情况如下表。
      </p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>序号</th>
              <th>废物类型</th>
              <th>废物代码</th>
              <th>废物名称</th>
              <th>单位</th>
              <th>产生量</th>
              <th>行政区划</th>
              <th>单位名称</th>
              <th>许可证编号</th>
              <th>经营方式</th>
              <th>实际转移量</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <th>HW09</th>
              <th>900-005-09</th>
              <th>废液压油</th>
              <th>吨</th>
              <th>5</th>
              <th>常州市,金坛区</th>
              <th>常州市金坛金东环保工程有限公司</th>
              <th>JSCZ0413OOD013-4</th>
              <th>D9</th>
              <th>5</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>2</th>
              <th>HW09</th>
              <th>900-006-09</th>
              <th>废切削油</th>
              <th>吨</th>
              <th>13</th>
              <th>常州市,金坛区</th>
              <th>常州市金坛金东环保工程有限公司</th>
              <th>JSCZ0413OOD013-4</th>
              <th>D9</th>
              <th>13</th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  ),
  '淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目配套的环境保护设施调试信息公示': (
    <>
      <h2 className="document-h">
        淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目配套的环境保护设施调试信息公示
      </h2>
      <p className="document-p">
        根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
      </p>
      <h3>一、调试日期：</h3>
      <p className="document-p">计划调试开始时间：2024年07月11日</p>
      <p className="document-p">预计调试完成时间：2024年07月25日</p>
      <h3>二、征求公众意见的范围：</h3>
      <p className="document-p">
        本建设项目周边环境影响区域内的居民、单位等公众。
      </p>
      <h3>三、公众反馈方式：</h3>
      <p className="document-p">
        公众可将意见或建议来电、来信向淮安伟时科技有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <h3>四、建设单位名称及联系方式：</h3>
      <p className="document-p">建设单位：淮安伟时科技有限公司</p>
      <p className="document-p">联系地址：淮安市淮安区经济开发区赵倚楼路6号昆淮智创园7#、8#厂房</p>
      <p className="document-p">联系电话：15862667819</p>
      <p className="document-p">电子邮箱：zwkhq@ksways.com</p>
      <p className="document-t">淮安伟时科技有限公司</p>
      <p className="document-t">2024-07-10</p>
	</>
  ),
  '淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目竣工公示': (
    <>
      <h2 className="document-h">
        淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目竣工公示
      </h2>
      <p className="document-p">
        由淮安伟时科技有限公司建设的“淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）”主体工程、配套工程及环境保护设施已于2024年7月10日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        <strong>
          公众可将意见或建议通过来电、来信的方式向淮安伟时科技有限公司
        </strong>
        反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">淮安市淮安区经济开发区赵倚楼路6号昆淮智创园7#、8#厂房</p>
      <p className="document-p">联系电话：15862667819</p>
      <p className="document-p">电子邮箱：zwkhq@ksways.com</p>
	  <p className="document-t">淮安伟时科技有限公司</p>
	  <p className="document-t">2024-07-9</p>
	</>
  ),
  昆山广兴电子有限公司建设项目配套的环境保护设施调试信息公示: (
    <>
      <h2 className="document-h">建设项目配套的环境保护设施调试信息公示</h2>
      <p className="document-p">
        根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“昆山广兴电子有限公司年产新型电子元器件300万件扩建项目”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
      </p>
      <h3>一、调试日期：</h3>
      <p className="document-p">计划调试开始时间：2023年08月05日</p>
      <p className="document-p">预计调试完成时间：2023年10月05日</p>
      <h3>二、征求公众意见的范围：</h3>
      <p className="document-p">
        本建设项目周边环境影响区域内的居民、单位等公众。
      </p>
      <h3>三、公众反馈方式：</h3>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山广兴电子有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <h3>四、建设单位名称及联系方式：</h3>
      <p className="document-p">建设单位：昆山广兴电子有限公司</p>
      <p className="document-p">联系地址：昆山开发区南浜路168号</p>
      <p className="document-p">联系电话：0512-57700108</p>
      <p className="document-p">电子邮箱：121521@sunon.com</p>
      <p className="document-t">昆山广兴电子有限公司</p>
      <p className="document-t">2024-08-05</p>
    </>
  ),
  昆山广兴电子有限公司建设项目竣工公示: (
    <>
      <h2 className="document-h">建设项目竣工公示</h2>
      <p className="document-p">
        由昆山广兴电子有限公司建设的“昆山广兴电子有限公司年产新型电子元器件300万件扩建项目”主体工程、配套工程及环境保护设施已于2024年6月10日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山广兴电子有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山开发区南浜路168号</p>
      <p className="document-p">联系电话：0512-57700108</p>
      <p className="document-p">电子邮箱：121521@sunon.com</p>
      <p className="document-t">昆山广兴电子有限公司</p>
      <p className="document-t">2024年-06月-10日</p>
    </>
  ),
  '昆山淀富精密组件有限公司金属零件生产项目（第一阶段）竣工环境保护验收公示': (
    <>
      <h2 className="document-h">
        昆山淀富精密组件有限公司金属零件生产项目（第一阶段）竣工环境保护验收公示
      </h2>
      <p className="document-p">
        根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
        <strong>
          昆山淀富精密组件有限公司金属零件生产项目（第一阶段）已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
        </strong>
      </p>
      <p className="document-p">
        <strong>
          公众可将意见或建议通过来电、来信的方式向昆山淀富精密组件有限公司
        </strong>
        反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">公示期：2024年8月13日至2024年9月10日</p>
      <p className="document-p">联系地址：昆山市玉山镇新塘路777号7号房</p>
      <p className="document-p">联系电话：18913268527</p>
      <p className="document-p">电子邮箱：js.zjl@hk-dianfu.com</p>
      <p className="document-p">具体情况如下：</p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>建设单位</th>
              <th>项目名称</th>
              <th>建设地点</th>
              <th>验收监测报告编制单位</th>
              <th>公示文件</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>昆山淀富精密组件有限公司</td>
              <td>昆山淀富精密组件有限公司金属零件生产项目（第一阶段）</td>
              <td>昆山市玉山镇新塘路777号7号房</td>
              <td>昆山昆越环境技术有限公司</td>
              <td>详见附件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="document-t">昆山淀富精密组件有限公司</p>
      <p className="document-t">2024-08-13</p>
      <p className="document-p">
        <div className="margin-10">附件：</div>
        <div className="margin-10">
          <a href="/PDF/12验收报告.pdf" target="_down">
            1 昆山淀富精密组件有限公司-验收监测报告.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/12一般变动变动环境影响分析.pdf" target="_down">
            2 昆山淀富精密组件有限公司 一般变动分析.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/12其他需要说明的事项.pdf" target="_down">
            3 昆山淀富精密组件有限公司 其他需要说明的事项.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/12验收意见.pdf" target="_down">
            4 昆山淀富精密组件有限公司 验收意见.pdf
          </a>
        </div>
      </p>
    </>
  ),
  'DAUNX昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目竣工公示': (
    <>
      <h2 className="document-h">
        DAUNX昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目竣工公示
      </h2>
      <p className="document-p">
        由昆山淀富精密组件有限公司建设的“昆山淀富精密组件有限公司金属零件生产项目（第一阶段）”主体工程、配套工程及环境保护设施已于2024年7月17日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山淀富精密组件有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山市玉山镇新塘路777号7号房</p>
      <p className="document-p">联系电话：18913268527</p>
      <p className="document-p">电子邮箱：js.zjl@hk-dianfu.com</p>
      <p className="document-t">昆山淀富精密组件有限公司</p>
      <p className="document-t">2024年-07月-17日</p>
    </>
  ),
  '昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目配套的环境保护设施调试信息公示':
    (
      <>
        <h2 className="document-h">
          昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目配套的环境保护设施调试信息公示
        </h2>
        <p className="document-p">
          根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“昆山淀富精密组件有限公司金属零件生产项目（第一阶段）”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
        </p>
        <h3>一、调试日期：</h3>
        <p className="document-p">计划调试开始时间：2023年7月17日</p>
        <p className="document-p">预计调试完成时间：2023年8月31日</p>
        <h3>二、征求公众意见的范围：</h3>
        <p className="document-p">
          本建设项目周边环境影响区域内的居民、单位等公众。
        </p>
        <h3>三、公众反馈方式：</h3>
        <p className="document-p">
          公众可将意见或建议来电、来信向苏州鑫腾塑料制品有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
        </p>
        <h3>四、建设单位名称及联系方式：</h3>
        <p className="document-p">建设单位：昆山淀富精密组件有限公司</p>
        <p className="document-p">联系地址：昆山市玉山镇新塘路777号7号房</p>
        <p className="document-p">联系电话：18913268527</p>
        <p className="document-p">电子邮箱：js.zjl@hk-dianfu.com</p>
        <p className="document-t">昆山淀富精密组件有限公司</p>
        <p className="document-t">2024-07-17</p>
      </>
    ),
  '苏州鑫腾塑料制品有限公司塑料制品生产项目（第一阶段）竣工环境保护验收公示': (
    <>
      <h2 className="document-h">
        苏州鑫腾塑料制品有限公司塑料制品生产项目（第一阶段）
        竣工环境保护验收公示
      </h2>
      <p className="document-p">
        根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
        <strong>
          苏州鑫腾塑料制品有限公司塑料制品生产项目（第一阶段）已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
        </strong>
      </p>
      <p className="document-p">
        <strong>
          公众可将意见或建议通过来电、来信的方式向苏州鑫腾塑料制品有限公司
        </strong>
        反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">公示期：自公示之日起不少于20个工作日</p>
      <p className="document-p">联系地址：昆山市锦溪镇百胜路288号9号房</p>
      <p className="document-p">联系电话：0512-57237083</p>
      <p className="document-p">电子邮箱：xinteng@wonwo.com.cn</p>
      <p className="document-p">具体情况如下：</p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>建设单位</th>
              <th>项目名称</th>
              <th>建设地点</th>
              <th>验收监测报告编制单位</th>
              <th>公示文件</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>苏州鑫腾塑料制品有限公司</td>
              <td>苏州鑫腾塑料制品有限公司塑料制品生产项目（第一阶段）</td>
              <td>昆山市锦溪镇百胜路288号9号房</td>
              <td>昆山昆越环境技术有限公司</td>
              <td>详见附件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="document-t">苏州鑫腾塑料制品有限公司</p>
      <p className="document-t">2024-07-05</p>
      <p className="document-p">
        <div className="margin-10">附件：</div>
        <div className="margin-10">
          <a href="/PDF/1 鑫腾塑料制品-验收监测报告.pdf" target="_down">
            1 鑫腾塑料制品-验收监测报告.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/2 鑫腾塑料制品 一般变动分析.pdf" target="_down">
            2 鑫腾塑料制品 一般变动分析.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/3 鑫腾塑料制品 其他需要说明的事项.pdf" target="_down">
            3 鑫腾塑料制品 其他需要说明的事项.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/4 鑫腾验收意见.pdf" target="_down">
            4 鑫腾验收意见.pdf
          </a>
        </div>
      </p>
    </>
  ),
  建设项目配套的环境保护设施调试信息公示: (
    <>
      <h2 className="document-h">建设项目配套的环境保护设施调试信息公示</h2>
      <p className="document-p">
        根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“苏州鑫腾塑料制品有限公司塑料制品生产项目”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
      </p>
      <h3>一、调试日期：</h3>
      <p className="document-p">计划调试开始时间：2023年4月15日</p>
      <p className="document-p">预计调试完成时间：2023年6月15日</p>
      <h3>二、征求公众意见的范围：</h3>
      <p className="document-p">
        本建设项目周边环境影响区域内的居民、单位等公众。
      </p>
      <h3>三、公众反馈方式：</h3>
      <p className="document-p">
        公众可将意见或建议来电、来信向苏州鑫腾塑料制品有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <h3>四、建设单位名称及联系方式：</h3>
      <p className="document-p">建设单位：苏州鑫腾塑料制品有限公司</p>
      <p className="document-p">联系地址：昆山市锦溪镇百胜路288号9号房</p>
      <p className="document-p">联系电话：0512-57237083</p>
      <p className="document-p">电子邮箱：xinteng@wonwo.com.cn</p>
      <p className="document-t">苏州鑫腾塑料制品有限公司</p>
      <p className="document-t">2024-04-15</p>
    </>
  ),
  建设项目竣工公示: (
    <>
      <h2 className="document-h">
        苏州鑫腾塑料制品有限公司塑料制品生产项目竣工公示
      </h2>
      <p className="document-p">
        由苏州鑫腾塑料制品有限公司建设的“苏州鑫腾塑料制品有限公司塑料制品生产项目”主体工程、配套工程及环境保护设施已于2024年4月5日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向苏州鑫腾塑料制品有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山市锦溪镇百胜路288号9号房</p>
      <p className="document-p">联系电话：0512-57237083</p>
      <p className="document-p">电子邮箱：xinteng@wonwo.com.cn</p>
      <p className="document-t">苏州鑫腾塑料制品有限公司</p>
      <p className="document-t">2024年-04月-10日</p>
    </>
  ),
  昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工公示:
    (
      <>
        <h2 className="document-h">
          昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工公示
        </h2>
        <p className="document-p">
          由昆山市工业技术研究院小核酸生物技术研究所有限责任公司建设的昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目的主体工程、配套工程及环境保护设施已于2013年05月10日全部完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
        </p>
        <p className="document-p">
          公众可将意见或建议来电、来信向昆山达浩兴包装有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
        </p>
        <p className="document-p">特此公告！</p>
        <p className="document-p">联系地址：昆山市玉山镇元丰路168号</p>
        <p className="document-p">联系电话：15601551992</p>
        <p className="document-p">电子邮箱：1783068738@qq.com</p>
        <p className="document-t">
          昆山市工业技术研究院小核酸生物技术研究所有限责任公司
        </p>
        <p className="document-t">2023年-11月-20日</p>
      </>
    ),
  上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工公示: (
    <>
      <h2 className="document-h">
        上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工公示
      </h2>
      <p className="document-p">
        由上海聚龙加油站管理有限公司昆山南港加油站建设的上海聚龙加油站管理有限公司昆山南港加油站建设项目的主体工程、配套工程及环境保护设施已于2016年12月全部完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山达浩兴包装有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山市张浦镇南港苏虹机场路南侧</p>
      <p className="document-p">联系电话：15601551992</p>
      <p className="document-p">电子邮箱：nang-jyz@concordoid.com</p>
      <p className="document-t">上海聚龙加油站管理有限公司昆山南港加油站</p>
      <p className="document-t">2023年-11月-1日</p>
    </>
  ),
  昆山电子羽电业制品有限公司建设项目竣工公示: (
    <>
      <h2 className="document-h">建设项目竣工公示</h2>
      <p className="document-p">
        由昆山电子羽电业制品有限公司建设的“昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目”主体工程、配套工程及环境保护设施已于2023年3月10日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山电子羽电业制品有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：苏州市昆山市锦溪镇锦东路258号</p>
      <p className="document-p">联系电话：0512-50132588</p>
      <p className="document-p">电子邮箱：bj-jh05@lsjm.cc</p>
      <p className="document-t">昆山电子羽电业制品有限公司</p>
      <p className="document-t">2023-04-10</p>
    </>
  ),
  茂宇昌竣工公示: (
    <>
      <h2 className="document-h">
        昆山茂宇昌精密模具有限公司塑料制品加工项目竣工公示
      </h2>
      <p className="document-p">
        由昆山茂宇昌精密模具有限公司建设的昆山茂宇昌精密模具有
        限公司塑料制品加工项目的主体工程、配套工程及环境保护设施已于 2023 年 03
        月全部完成竣工。根据《建设项目竣工环境保护验收暂行
        办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山茂宇昌精密模具有限公司
        反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山市锦溪镇昆开路 28 号 6 号房</p>
      <p className="document-p">联系电话：15601551992</p>
      <p className="document-p">电子邮箱：1783068738@qq.com</p>
      <p className="document-t">昆山茂宇昌精密模具有限公司</p>
      <p className="document-t">2023-04</p>
    </>
  ),
  '立讯精密工业（昆山）有限公司建设项目配套的环境保护设施调试信息公示': (
    <>
      <h2 className="document-h">建设项目配套的环境保护设施调试信息公示</h2>
      <p className="document-p">
        根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
      </p>
      <h3>一、调试日期：</h3>
      <p className="document-p">计划调试开始时间：2023年5月20日</p>
      <p className="document-p">预计调试完成时间：2023年8月20日</p>
      <h3>二、征求公众意见的范围：</h3>
      <p className="document-p">
        本建设项目周边环境影响区域内的居民、单位等公众。
      </p>
      <h3>三、公众反馈方式：</h3>
      <p className="document-p">
        公众可将意见或建议来电、来信向立讯精密工业（昆山）有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <h3>四、建设单位名称及联系方式：</h3>
      <p className="document-p">建设单位：立讯精密工业（昆山）有限公司</p>
      <p className="document-p">联系地址：昆山市锦溪镇锦商路851号</p>
      <p className="document-p">联系电话：0512-82622200</p>
      <p className="document-p">电子邮箱：Cibin.He@luxshare-ict.com</p>
      <p className="document-t">立讯精密工业（昆山）有限公司</p>
      <p className="document-t">2023-05-20</p>
    </>
  ),
  '立讯精密工业（昆山）有限公司建设项目竣工公示': (
    <>
      <h2 className="document-h">建设项目竣工公示</h2>
      <p className="document-p">
        由立讯精密工业（昆山）有限公司建设的“立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目”主体工程、配套工程及环境保护设施已于2023年5月10日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向立讯精密工业（昆山）有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山市锦溪镇锦商路851号</p>
      <p className="document-p">联系电话：0512-82622200</p>
      <p className="document-p">电子邮箱：Cibin.He@luxshare-ict.com</p>
      <p className="document-t">立讯精密工业（昆山）有限公司</p>
      <p className="document-t">2023-05-12</p>
    </>
  ),
  '立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目竣工环境保护验收公示':
    (
      <>
        <h2 className="document-h">
          立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目竣工环境保护验收公示
        </h2>
        <p className="document-p">
          根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
          <strong>
            立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
          </strong>
        </p>
        <p className="document-p">
          <strong>
            公众可将意见或建议通过来电、来信的方式向立讯精密工业（昆山）有限公司
          </strong>
          反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
        </p>
        <p className="document-p">公示期：自公示之日起不少于20个工作日</p>
        <p className="document-p">联系地址：昆山市锦溪镇锦商路851号</p>
        <p className="document-p">联系电话：0512-82622200</p>
        <p className="document-p">电子邮箱：Cibin.He@luxshare-ict.com</p>
        <p className="document-p">具体情况如下：</p>
        <div className="document-div">
          <table className="document-table" border="1px" cellSpacing="0">
            <thead>
              <tr>
                <th>建设单位</th>
                <th>项目名称</th>
                <th>建设地点</th>
                <th>验收监测报告编制单位</th>
                <th>公示文件</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>立讯精密工业（昆山）有限公司</td>
                <td>
                  立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目
                </td>
                <td>昆山市锦溪镇锦商路851号</td>
                <td>昆山昆越环境技术有限公司</td>
                <td>详见附件</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="document-t">立讯精密工业（昆山）有限公司</p>
        <p className="document-t">2023-08-02</p>
        <p className="document-p">
          <div className="margin-10">附件：</div>
          <div className="margin-10">
            <a href="/PDF/1 立讯精密技改项目-验收监测报告.pdf" target="_down">
              1 立讯精密技改项目-验收监测报告.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/2 立讯精密技改 一般变动分析.pdf" target="_down">
              2 立讯精密技改 一般变动分析.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/3 立讯精密技改 其他需要说明的事项.pdf" target="_down">
              3 立讯精密技改 其他需要说明的事项.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/4 立讯精密技改验收意见+签到表.pdf" target="_down">
              4 立讯精密技改验收意见+签到表.pdf
            </a>
          </div>
        </p>
      </>
    ),
  '昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工公示': (
    <>
      <h2 className="document-h">
        昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工公示
      </h2>
      <p className="document-p">
        由昆山达浩兴包装有限公司建设的昆山达浩兴包装有限公司纸箱生产项目（第一阶段）的主体工程、配套工程及环境保护设施已于2023年07月27日全部完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山达浩兴包装有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山开发区环娄路325 号9 号房一层</p>
      <p className="document-p">联系电话：13706267882</p>
      <p className="document-p">电子邮箱：1783068738@qq.com</p>
      <p className="document-t">昆山达浩兴包装有限公司</p>
      <p className="document-t">2023年07月27日</p>
    </>
  ),
  '昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目竣工环境保护验收公示':
    (
      <>
        <h2 className="document-h">
          昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目竣工环境保护验收公示
        </h2>
        <p className="document-p">
          根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
          <strong>
            昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
          </strong>
        </p>
        <p className="document-p">
          <strong>
            公众可将意见或建议通过来电、来信的方式向昆山电子羽电业制品有限公司
          </strong>
          反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
        </p>
        <p className="document-p">公示期：自公示之日起不少于20个工作日</p>
        <p className="document-p">联系地址：昆山市锦溪镇锦东路258号</p>
        <p className="document-p">联系电话：0512-50132588</p>
        <p className="document-p">电子邮箱：bj-jh05@lsjm.cc</p>
        <p className="document-p">具体情况如下：</p>
        <div className="document-div">
          <table className="document-table" border="1px" cellSpacing="0">
            <thead>
              <tr>
                <th>建设单位</th>
                <th>项目名称</th>
                <th>建设地点</th>
                <th>验收监测报告编制单位</th>
                <th>公示文件</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>昆山电子羽电业制品有限公司</td>
                <td>
                  昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目
                </td>
                <td>昆山市锦溪镇锦东路258号</td>
                <td>昆山昆越环境技术有限公司</td>
                <td>详见附件</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="document-t">昆山电子羽电业制品有限公司</p>
        <p className="document-t">2023-09-17</p>
        <p className="document-p">
          <div className="margin-10">附件：</div>
          <div className="margin-10">
            <a href="/PDF/1 电子羽技改项目-验收监测报告.pdf" target="_down">
              1 电子羽技改项目-验收监测报告.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/2 电子羽技改 一般变动分析.pdf" target="_down">
              2 电子羽技改 一般变动分析.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/3 电子羽技改 其他需要说明的事项.pdf" target="_down">
              3 电子羽技改 其他需要说明的事项.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/4验收意见.pdf" target="_down">
              4 验收意见.pdf
            </a>
          </div>
        </p>
      </>
    ),
  '昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工环境保护验收公示': (
    <>
      <h2 className="document-h">
        昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工环境保护验收公示
      </h2>
      <p className="document-p">
        根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
        昆山达浩兴包装有限公司纸箱生产项目（第一阶段）已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
      </p>
      <p className="document-p">
        公众可将意见或建议通过来电、来信的方式向昆山达浩兴包装有限公司反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">公示期：2023年9月25日至2023年10月27日</p>
      <p className="document-p">联系地址：昆山开发区环娄路325 号9 号房一层</p>
      <p className="document-p">联系电话：13706267882</p>
      <p className="document-p">电子邮箱：1783068738@qq.com</p>
      <p className="document-p">具体情况如下：</p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>建设单位</th>
              <th>项目名称</th>
              <th>建设地点</th>
              <th>验收监测报告编制单位</th>
              <th>公示文件</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>昆山达浩兴包装有限公司</td>
              <td>昆山达浩兴包装有限公司纸箱生产项目（第一阶段）</td>
              <td>昆山开发区环娄路325 号9 号房一层</td>
              <td>昆山昆越环境技术有限公司</td>
              <td>详见附件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="document-p">
        <div className="margin-10">附件：</div>
        <div className="margin-10">
          <a href="/PDF/8其他需要说明的事项.pdf" target="_down">
            其他需要说明的事项.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/8验收报告.pdf" target="_down">
            验收报告.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/8验收意见.pdf" target="_down">
            验收意见.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/8一般变动变动环境影响分析.pdf" target="_down">
            一般变动变动环境影响分析.pdf
          </a>
        </div>
      </p>
    </>
  ),
  昆山茂宇昌精密模具有限公司塑料制品加工项目竣工环境保护验收公示: (
    <>
      <h2 className="document-h">
        昆山茂宇昌精密模具有限公司塑料制品加工项目竣工环境保护验收公示
      </h2>
      <p className="document-p">
        根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
        昆山茂宇昌精密模具有限公司塑料制品加工项目已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
      </p>
      <p className="document-p">
        公众可将意见或建议通过来电、来信的方式向昆山达浩兴包装有限公司反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">公示期：2023年9月12日至2023年10月13日</p>
      <p className="document-p">联系地址：昆山市锦溪镇昆开路28号6号房</p>
      <p className="document-p">联系电话：15601551992</p>
      <p className="document-p">电子邮箱：1783068738@qq.com</p>
      <p className="document-p">具体情况如下：</p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>建设单位</th>
              <th>项目名称</th>
              <th>建设地点</th>
              <th>验收监测报告编制单位</th>
              <th>公示文件</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>昆山茂宇昌精密模具有限公司</td>
              <td>昆山茂宇昌精密模具有限公司塑料制品加工项目</td>
              <td>昆山市锦溪镇昆开路28号6号房</td>
              <td>昆山昆越环境技术有限公司</td>
              <td>详见附件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="document-p">
        <div className="margin-10">附件：</div>
        <div className="margin-10">
          <a href="/PDF/9其他需要说明的事项.pdf" target="_down">
            其他需要说明的事项.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/9验收报告.pdf" target="_down">
            验收报告.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/9验收意见.pdf" target="_down">
            验收意见.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/9一般变动变动环境影响分析.pdf" target="_down">
            一般变动变动环境影响分析.pdf
          </a>
        </div>
      </p>
    </>
  ),
  上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工环境保护验收公示: (
    <>
      <h2 className="document-h">
        上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工环境保护验收公示
      </h2>
      <p className="document-p">
        根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。上海聚龙加油站管理有限公司昆山南港加油站建设项目已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
      </p>
      <p className="document-p">
        公众可将意见或建议通过来电、来信的方式向上海聚龙加油站管理有限公司昆山南港加油站反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">公示期：2024年1月30日至2024年2月29日</p>
      <p className="document-p">联系地址：昆山市张浦镇南港苏虹机场路南侧</p>
      <p className="document-p">联系电话：15601551992</p>
      <p className="document-p">电子邮箱：nang-jyz@concordoid.com@qq.com</p>
      <p className="document-p">具体情况如下：</p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>建设单位</th>
              <th>项目名称</th>
              <th>建设地点</th>
              <th>验收监测报告编制单位</th>
              <th>公示文件</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>上海聚龙加油站管理有限公司昆山南港加油站</td>
              <td>上海聚龙加油站管理有限公司昆山南港加油站建设项目</td>
              <td>昆山市张浦镇南港苏虹机场路南侧</td>
              <td>昆山昆越环境技术有限公司</td>
              <td>详见附件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="document-p">
        <div className="margin-10">附件：</div>
        <div className="margin-10">
          <a href="/PDF/10其他需要说明的事项.pdf" target="_down">
            其他需要说明的事项.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/10验收报告.pdf" target="_down">
            验收报告.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/10验收意见.pdf" target="_down">
            验收意见.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/10一般变动环境影响分析.pdf" target="_down">
            一般变动变动环境影响分析.pdf
          </a>
        </div>
      </p>
    </>
  ),
  昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工环境保护验收公示:
    (
      <>
        <h2 className="document-h">
          昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工环境保护验收公示
        </h2>
        <p className="document-p">
          根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。上海聚龙加油站管理有限公司昆山南港加油站建设项目已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
        </p>
        <p className="document-p">
          公众可将意见或建议通过来电、来信的方式向昆山市工业技术研究院小核酸生物技术研究所有限责任公司反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
        </p>
        <p className="document-p">公示期：2024年1月30日至2024年2月29日</p>
        <p className="document-p">联系地址：昆山市玉山镇元丰路168号</p>
        <p className="document-p">联系电话：15601551992</p>
        <p className="document-p">电子邮箱：1783068738@qq.com</p>
        <p className="document-p">具体情况如下：</p>
        <div className="document-div">
          <table className="document-table" border="1px" cellSpacing="0">
            <thead>
              <tr>
                <th>建设单位</th>
                <th>项目名称</th>
                <th>建设地点</th>
                <th>验收监测报告编制单位</th>
                <th>公示文件</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>昆山市工业技术研究院小核酸生物技术研究所有限责任公司</td>
                <td>
                  昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目
                </td>
                <td>昆山市玉山镇元丰路168号</td>
                <td>昆山昆越环境技术有限公司</td>
                <td>详见附件</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="document-p">
          <div className="margin-10">附件：</div>
          <div className="margin-10">
            <a href="/PDF/11其他需要说明的事项.pdf" target="_down">
              其他需要说明的事项.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/11验收报告.pdf" target="_down">
              验收报告.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/11验收意见.pdf" target="_down">
              验收意见.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/11一般变动变动环境影响分析.pdf" target="_down">
              一般变动变动环境影响分析.pdf
            </a>
          </div>
        </p>
      </>
    )
};

const AnnouncementDetail = () => {
  const localData = useLocation();
  return (
    <div>
      <Header />
      <div className="announcement-wrapper">
        <div className="announcement-content">
          <div className="announcement-title">公告</div>
          <div className="announcement-sub">announcement</div>
          <div className="announcement-line"></div>
          <div className="announcement-container">
            {domObj[localData.state.key || '']}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AnnouncementDetail;
